import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { MaybeRef, Ref, UnwrapRef } from 'vue'
import { chunk } from 'lodash-es'
import type { DefaultError } from '@tanstack/query-core'
import { type components, ContractPricesOverviewStatus } from '~/schema'
import type { AccommodationId } from '~/composables/accommodation'
import type Slider from '~/components/basic/Slider.vue'

export type ContractPricesResponse =
  components['schemas']['RenewalPricesResponse']

export type RenewalContract = components['schemas']['ContractPricesOverview']
export type YearlyPeriodicPrice = components['schemas']['YearlyPeriodicPrice']
export type PeriodicPrice = components['schemas']['PeriodicPrice']

export function useContractsPricesQuery(
  accommodationId: MaybeRef<AccommodationId | undefined>,
) {
  const fetch = useApiFetch()
  const { $unleash, $auth } = useNuxtApp()

  return useQuery({
    enabled: !!toValue(accommodationId),
    queryKey: ['contracts-prices', accommodationId] as const,
    queryFn({ signal, queryKey: [_, id] }) {
      return fetch<ContractPricesResponse>(
        `/api/prices/${toValue(id)}?activeContractsLimit=3`,
        {
          signal,
        },
      )
    },
    select(data) {
      return {
        ...data,
        contracts: data.contracts
          .filter((contract) =>
            $unleash.features.opportunities &&
            $auth.profile.permissions?.ViewOpportunities
              ? true
              : contract.status,
          )
          .map((contract) => {
            if (!contract.status) {
              return { ...contract, id: 0 }
            }
            return contract
          }),
      }
    },
  })
}

type NormalizedYearlyRenewalPrice = {
  status: ContractPricesOverviewStatus
  yearlyRenewalPrices: YearlyPeriodicPrice[][]
  guestCountList: number[]
}

export function useContractPriceId(
  renewalContracts: MaybeRef<
    Pick<
      RenewalContract,
      'from' | 'to' | 'id' | 'status' | 'contractAttentionLevel'
    >[]
  >,
  preselectContractWithAttentionLevel = false,
) {
  const currentTime = new Date().getTime()

  return computed(() => {
    if (unref(renewalContracts) === undefined) return

    const lastContract =
      unref(renewalContracts)[unref(renewalContracts).length - 1]

    if (preselectContractWithAttentionLevel) {
      return lastContract.id.toString()
    }

    const pendingContract = unref(renewalContracts).find(
      (contract) => ContractPricesOverviewStatus.PENDING === contract.status,
    )

    if (pendingContract) {
      return pendingContract.id.toString()
    }

    const currentActiveContract = unref(renewalContracts).find(
      (contract) =>
        new Date(contract.from).getTime() <= currentTime &&
        currentTime <= new Date(contract.to).getTime(),
    )
    const contract = currentActiveContract || lastContract
    return contract.id.toString()
  })
}

export function useContractPriceTable(
  renewalContracts: MaybeRef<RenewalContract[]>,
) {
  return computed(() =>
    unref(renewalContracts)
      .filter((contract) => Boolean(contract.id))
      .reduce(
        (acc, contract) => {
          const yearlyRenewalPrices: YearlyPeriodicPrice[] = []
          Object.values(contract.yearlyPriceMap).forEach((yearlyPrices) =>
            yearlyRenewalPrices.push(...yearlyPrices!.yearlyPeriodicPrices),
          )
          acc[contract.id.toString()] = {
            status: contract.status,
            guestCountList: contract.guestCountsList,
            yearlyRenewalPrices: chunk(yearlyRenewalPrices, 8),
          }
          return acc
        },
        {} as Record<string, NormalizedYearlyRenewalPrice>,
      ),
  )
}

const CONTEXT_ID = 'contractId' as const

type ContactPriceCtx<T = string | undefined> = {
  contractId: Ref<UnwrapRef<T>>
  setContractId(id: string | undefined): void
}

export function useContractPriceIdProvider<
  D extends string | undefined,
  T extends [D] extends [string] ? string : string | undefined,
>({ defaultValue }: { defaultValue?: D } = {}) {
  const contractId = ref<T>(defaultValue as T)
  const ctx: ContactPriceCtx<T> = {
    contractId,
    setContractId(id: string | undefined) {
      ;(contractId as Ref<string | undefined>).value = id
    },
  }
  provide(CONTEXT_ID, ctx)
  return ctx
}

export function useContractPriceIdContext<
  T extends string | undefined = string | undefined,
>() {
  return inject<ContactPriceCtx<T>>(CONTEXT_ID, {
    get contractId() {
      console.error('No Contract context provided')
      return ref<T>(undefined as T)
    },
    setContractId() {
      console.error('No Contract context provided')
    },
  })
}

export function useHasContractPriceYellowDot() {
  const { $unleash, $auth } = useNuxtApp()
  const { data: accommodations } = useAccommodationsQuery()

  return computed(() => {
    return (
      $auth.profile.permissions?.ViewContractPrices &&
      $unleash.features['contract-prices'] &&
      Boolean(unref(accommodations)?.some(isContractAttentionLevelYellow))
    )
  })
}

export type SwipeFingerStorage = {
  time: string
  visible: boolean
}

export const SwipeFingerKey = 'SwipeFingerKey'

export function useHasContractPriceSliderWiderThanWindow() {
  const sliderRef = ref<InstanceType<typeof Slider>>()

  const { width: windowWidth } = useWindowSize()
  const { width } = useElementSize(
    computed(() => sliderRef.value?.mobileSliderRef),
  )

  const isSliderWiderThanWindow = computed(
    () => width.value > windowWidth.value,
  )

  function handleSwipeLeft(
    direction: 'up' | 'down' | 'left' | 'right' | 'none',
  ) {
    if (direction !== 'left' || !isSliderWiderThanWindow.value) {
      return
    }

    const storage = useLocalStorage(
      SwipeFingerKey,
      () => ({}) as SwipeFingerStorage,
    )

    storage.value = { time: new Date().toISOString(), visible: false }
  }

  const handleSwipeLeftThrottledFn = useThrottleFn(handleSwipeLeft, 300)

  return {
    sliderRef,
    isSliderWiderThanWindow,
    handleSwipeLeftThrottledFn,
  }
}

type RenewalBody = {
  propertyId: string
  contractId: number
}

export const enum Modals {
  activate = 'activate',
  contactUs = 'contactUs',
}

export type ModalType = Modals | null

export function useContractPriceAction(modalType: MaybeRef<ModalType>) {
  const action = computed(() =>
    unref(modalType) === Modals.activate ? 'renewal' : 'request-contact',
  )
  const queryClient = useQueryClient()
  const fetch = useApiFetch()
  return useMutation({
    mutationFn({
      propertyId,
      contractId,
    }: {
      propertyId: string
      contractId: number
    }) {
      return fetch(`/api/prices/${propertyId}/${contractId}/${action.value}`, {
        method: 'POST',
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['contracts-prices'],
      })
      queryClient.invalidateQueries({
        queryKey: ['accommodations'],
      })
    },
  })
}

export function useReadRenewalMessage() {
  const fetch = useApiFetch()
  const queryClient = useQueryClient()
  return useMutation<boolean, DefaultError, RenewalBody>({
    mutationFn({
      propertyId,
      contractId,
    }: {
      propertyId: string
      contractId: number
    }) {
      return fetch(`/api/prices/${propertyId}/${contractId}/message-read`, {
        method: 'POST',
      })
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ['contracts-prices'],
      })
    },
  })
}
